import { HttpClient, HttpHeaders } from '@angular/common/http';
import { KeycloakSecurityService } from '../../Keycloak-config/keycloak-security.service';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Organization } from '../../Models/organization/entities/organization.entity';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  private apiUrl = environment.API_URL;

  private organizationsSubject = new BehaviorSubject<any[]>([]);
  organizations$ = this.organizationsSubject.asObservable();

  private selectedOrganizationIdSubject = new BehaviorSubject<string>(null);
  selectedOrganizationId$ = this.selectedOrganizationIdSubject.asObservable();

  private localStorageKey = 'selectedOrganizationId';

  constructor(private http: HttpClient, private keycloakSecurityService:KeycloakSecurityService) {
    const savedOrgId = localStorage.getItem(this.localStorageKey);
    if (this.keycloakSecurityService.kc.tokenParsed.realm_access.roles.includes('admin')){
      this.setSelectedOrganizationId(savedOrgId);
      this.getOrganizations().subscribe((response: any[]) => {
        this.updateOrganizationsState(response);

        // Select the saved organization if it exists and matches the retrieved list
        const matchedOrganization = response.find(org => org.organization_id === savedOrgId);

        if (!matchedOrganization && response.length > 0) {
          // Default to the first organization if no match is found
          this.setSelectedOrganizationId(response[0].organization_id);
        }
      });
    }
  }

  getOrganization(organization_id: string) {
    try {
      return this.http.get(`${this.apiUrl}/organization/${organization_id}`);
    } catch (error) {
      throw new Error(error);
    }
  }
  getOrganizations() {
    try {
      return this.http.get(`${this.apiUrl}/organization/`);
    } catch (error) {
      throw new Error(error);
    }
  }

  addOrganization(organization: Organization) {
    return this.http.post(this.apiUrl+'/organization/',
    organization,
    );
  }
  updateOrganization(organization: Organization) {
    return this.http.patch(this.apiUrl+`/organization/${organization.organization_id}`,
    organization,
    );
  }

  deleteOrganization(organization_id: string) {
    try {
      return this.http.delete(this.apiUrl + `/organization/${organization_id}`)
    } catch (error) {
      throw new Error(error);
    }
  }

  updateOrganizationsState(organizations: any[]): void {
    this.organizationsSubject.next(organizations);
  }

  // Manage selected organization
  setSelectedOrganizationId(organizationId: string): void {
    this.selectedOrganizationIdSubject.next(organizationId);
    localStorage.setItem(this.localStorageKey, organizationId);

    environment.ORGANIZATION_ID = organizationId; // Update the environment value
  }

  getSelectedOrganizationId(): string {
    return this.selectedOrganizationIdSubject.getValue();
  }
}
